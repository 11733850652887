<template>
  <keep-alive>
    <component :is=currentComponent :modus=currentModus />
  </keep-alive>
</template>

<script>

import ClientNew from "client/new/ClientNew.vue"
import ClientTable from "client/page/ClientTable.vue"
import ClientPanel from "client/page/ClientPanel.vue"

import {eventBus} from "main"

export default {
  name: "ClientPage",
  title: "Абоненты",
  components: {},
  
  data()
  {
    return {
      currentComponent: null,
      currentModus: {path: null, params: null},
    }
  },
  
  created()
  {
    eventBus.on("routeChanged", ({path,params}) => this.setState(path,params))
  },
  
  methods: {
    setState(path,params)
    {
      if (path.match(/\/client\/\d+/))
      {
        this.currentComponent = ClientPanel
      }
      else if (path=="/client/!new")
      {
        this.currentComponent = ClientNew
      }
      else
      {
        this.currentComponent = ClientTable
      }

      this.currentModus = {path, params}
    },
  },

  computed: {
  },
                                                                    
}

</script>
