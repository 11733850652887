export default [
          { modPath: 'client/page/info', name: "edit", icon: "user", text: "Данные", defaultTab: true, subpath: "" },
          { modPath: 'client/page/ppp', text: "PPPoE", icon: "link", name: "clientPPPTab", defaultTab: true, subpath: "ppp" },
          { modPath: 'client/ipoe/ipoe-glue', text: "IPoE", icon: "fa-plug", name: 'clientIpoeTab', defaultTab: true, subpath: "ipoe" },
          { modPath: 'client/ppp/glue', text: "PPPoE+", icon: "link", name: 'clientPPP2Tab', defaultTab: true, subpath: "ppp2" },
          { modPath: 'client/iptv/iptv-glue', text: "IPTV", icon: "fa-tv", name: 'clientIPTVTab', subpath: "iptv" },
          { modPath: 'client/sms/sms', name: "sms", icon: "envelope", text: "Сообщения", subpath: "sms" },
          { modPath: 'client/history/ClientHistory', name: "history", icon: "book", text: "История", subpath: "history" },
          { modPath: 'client/plan-log/plan-log', name: "plan-log", icon: "fa-flag-checkered", text: "Выполнение планов", subpath: "plan-log" },
          { modPath: 'client/offside-error/offside-error', name: "offside-error", icon: "fa-frown-o", text: "Ошибки отложенных действий", subpath: "offside-error" },
          { modPath: 'client/instalment/instalment-glue', name: "clientInstalmentTab", icon: "piggy-bank", text: "Рассрочки", subpath: "instalment" },
          { modPath: 'client/action/action-glue', name: "clientActionTab", icon: "fa-birthday-cake", text: "Акции", subpath: "action" },
          { modPath: 'client/document/document', name: "document", icon: "file", text: "Документы", subpath: "document" },
          { modPath: 'client/cron/cron', name: "cron", icon: "hourglass", text: "Отключение", subpath: "auto"},
          { modPath: 'client/record/record', name: "record", icon: "earphone", text: "Записи", subpath: "record" },
          { modPath: 'client/corporate-pool/corporate-pool-glue', name: "clientCorporatePoolTab", text: "Корпоративные пулы", icon: "cloud", subpath: "corporate-pool" },
          { modPath: 'client/simple-service/simple-service-glue', name: 'clientSimpleServiceTab', icon: 'shopping-cart', text: 'Дополнительные сервисы', subpath: "simple-service"},
          { modPath: 'client/trinity/glue', name: 'clientTrinityTab', icon: 'fa-tv', text: 'Тринити ТВ', subpath: "trinity"},
          { modPath: 'client/omegatv/glue', name: 'clientOmegatvTab', icon: 'fa-tv', text: 'Omega TV', subpath: "omega"},
          { modPath: 'client/megogo/glue', name: 'clientMegogoTab', icon: 'fa-tv', text: 'Megogo TV', subpath: "megogo"},
          { modPath: 'client/eset/page/eset-page', name: 'clientEsetTab', icon: 'fa-key', text: 'ESET license', subpath: "eset" },
          { modPath: 'client/cron-full/cron-full', name: 'clientCronFullTab', icon: 'time', text: 'Отложенные операции', subpath: "cron"},
          { modPath: 'client/corporate/ClientCorporate', name: 'clientCorporateTab', icon: 'briefcase', text: 'Корпоративные данные', subpath: "corporate"},
          { modPath: 'client/corpserv/CorpService', name: 'clientCorpSrvTab', icon: 'fa-cogs', text: 'Корпоративные сервисы', subpath: "corpserv"},
          { modPath: 'client/bundle/bundle-glue', name: "clientBundleTab", icon: "gift", text: "Пакеты", subpath: "bundle" },
          { modPath: 'client/store/store', name: "store", icon: "magnet", text: "Оборудование", subpath: "store" },
      ]
