var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.validClient
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-8" },
            [
              _c("Tabs", {
                attrs: {
                  tabs: _vm.clientTabs,
                  state: _vm.state,
                  "info-tab-title": _vm.login,
                  uiList: _vm.$store.state.userSettings.uiClientTabs
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-4" },
            [
              _c(
                "Panel",
                {
                  attrs: {
                    title: "Новости",
                    icon: "home",
                    toolbar: _vm.newsToolbar,
                    "low-toolbar": true
                  },
                  on: { "toolbar-click": _vm.newsClick }
                },
                [
                  _c("RadixModule", {
                    ref: "news",
                    attrs: { path: "/client/news/news", state: _vm.state }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Panel",
                {
                  attrs: {
                    title: "Заметки",
                    icon: "comment",
                    toolbar: _vm.noteToolbar,
                    "low-toolbar": true
                  },
                  on: { "toolbar-click": _vm.noteClick }
                },
                [
                  _c("RadixModule", {
                    ref: "notes",
                    attrs: { path: "/client/note/note", state: _vm.state }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Panel",
                {
                  attrs: {
                    title: "Деньги",
                    icon: "usd",
                    toolbar: _vm.moneyToolbar,
                    "low-toolbar": true,
                    padding: true
                  },
                  on: { "toolbar-click": _vm.moneyClick }
                },
                [
                  _c("RadixModule", {
                    ref: "money",
                    attrs: { path: "/client/money/money", state: _vm.state }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "Panel",
                {
                  attrs: {
                    title: "Заявки",
                    icon: "tasks",
                    toolbar: _vm.taskToolbar,
                    "low-toolbar": true
                  },
                  on: { "toolbar-click": _vm.taskClick }
                },
                [
                  _c("RadixModule", {
                    ref: "tasks",
                    attrs: { path: "/client/task/list", state: _vm.state }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _c("h4", [_vm._v("Абонент " + _vm._s(_vm.state.uid) + " не найден")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }