define(['radio', 'common/visual/vertical'], function(radio, Vertical) {
  return Object.create(Vertical).extend({
    name: 'clientInfoTab',
    title: 'Данные',
    icon: 'user',

    layout: [
      { modPath: 'client/edit/info', noBorder: true },
      { modPath: 'client/tag/tag', noBorder: true },
      { modPath: 'client/file/file', noBorder: true },
    ],
  });
});
