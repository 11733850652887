define([
  'radio',
  'common/visual/vertical',
], function(radio,Vertical)
{
  'use strict';
  return Object.create(Vertical).extend({
    name: "clientPPPTab",
    title: "PPPoE",
    icon: "link",
    layout: [
      { modPath: 'client/pppoe/session', icon: "star", text: "Активные сессии", noPadding: true,
        toolbar: [
          { name: "reload-active", style: 'inline', text: "", icon: "refresh", callback: (mod) => mod.reload() },
        ]
      },
      { modPath: 'client/pppoe/edit', noBorder: true },

      { modPath: 'client/stat/stat-pppoe', icon: "star-empty", text: "Завершенные сессии (последний месяц)", noPadding: true,
        toolbar: [
          { name: "external-stat-pppoe", style: 'inline', text: "", icon: "fa-external-link", callback: (mod) => mod.openPPPErrorLog() },
          { name: "reload-old", style: "inline", text: "", icon: "refresh", callback: ( mod ) => mod.reload() }
        ]
      },

      { modPath: 'client/poller/poller-pppoe', icon: "hdd", text: "Poller", noPadding: true, 
        toolbar: [
          { name: "l2-stat", style: 'inline', text: "", icon: "glyphicon glyphicon-signal", callback: ( mod ) => mod.l2_stat() }
        ]
      },
    ],
    
    create: function(el, options)
    {
      var me = this;
      radio.channel("active-sessions-header").reply("setActive", (flag) => {
        var star = me.$("div.panel-heading span.glyphicon-star");
        if (flag) 
        {
          star.addClass("text-success").removeClass("text-danger");
        }
        else
        {
          star.removeClass("text-success").addClass("text-danger");
        }
      });

      return Vertical.create.apply(me,arguments);
    }
  });
});
